import activityLog from './activityLog'
import auth from './auth'
import categories from './categories/main'
import company from './company'
import costCenters from './costCenters/main'
import dashboard from './dashboard/main'
import editor from './editor'
import files from './files'
import globalSearch from './globalSearch'
import loginProviders from './loginProviders/main'
import mailTemplates from './mailTemplates'
import menu from './menu'
import notifications from './notifications'
import orders from './orders/main'
import organisations from './organisations/main'
import pageTitle from './pageTitle'
import requestLog from './requestLog'
import secret from './secret'
import settings from './settings'
import table from './table/main'
import tenders from './tenders/main'
import testUsers from './testUsers/main'
import users from './users/main'
import wiki from './wiki'

export default {
    localeHint: 'Dies ist die deutsche Version der Website.',
    canNotAccessRoute: 'Sie haben keine Berechtigung, auf diese Seite zu gehen.',

    serverError: 'Es liegt serverseitig ein Fehler vor.',
    serverErrorSupport: 'Falls sich dieser Fehler wiederholt wenden Sie sich bitte an den Support.',

    invalidRequestData: 'Die Anfrage enthält ungültige Daten.',
    modelDoesNotExist: 'Die angeforderte Ressource existiert nicht.',
    accessIsForbidden: 'Sie haben keine Berechtigung diese Daten einzusehen.',
    unauthorizedRequest: 'Sie sind nicht berechtigt, diese Seite aufzurufen.',

    baseData: 'Daten',

    resourceDoesNotExist: 'Der aufgerufene Eintrag existiert nicht, oder Sie haben keine Berechtigung diesen abzurufen.',
    resourceIsForbidden: 'Sie haben keine Berechtigung diesen Eintrag abzurufen.',
    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    noResults: 'Keine Ergebnisse',

    abort: 'Abbrechen',
    delete: 'Löschen',
    back: 'Zurück',
    save: 'Speichern',
    discard: 'Verwerfen',
    error: 'Fehler',
    open: 'Öffnen',
    show: 'Anzeigen',
    more: 'Weiteres',
    change: 'Ändern',
    add: 'Hinzufügen',
    welcome: 'Willkommen!',
    name: 'Name',

    pleaseSelect: 'Bitte auswählen',
    noResults: 'Keine Ergebnisse',

    locales: {
        de: 'Deutsch',
        en: 'English'
    },

    menu,
    files,
    globalSearch,
    activityLog,
    notifications,
    requestLog,
    settings,
    auth,
    pageTitle,
    wiki,
    secret,
    dashboard,

    table,

    users,
    organisations,
    loginProviders,
    testUsers,
    categories,
    costCenters,
    editor,
    mailTemplates,
    orders,
    company,
    tenders
}
