import { default as indexswghwu7igrMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/company/index.vue?macro=true";
import { default as index3a84ZWKTbdMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/index.vue?macro=true";
import { default as indexD9nNNF1MRWMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93XzmVa3bv2PMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/categories/[id].vue?macro=true";
import { default as indexfaHeR5ZBBiMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93euujCp3xZ6Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as indexaMdq93EbBzMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as indexVX5zJtz78dMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/mailTemplates/index.vue?macro=true";
import { default as baseDataGD4NBWjWHFMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData.vue?macro=true";
import { default as companyBaseDataC1ukXzbtsrMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/company/companyBaseData.vue?macro=true";
import { default as companyogaHkdCGkEMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/company.vue?macro=true";
import { default as indexOillnGM69YMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/index.vue?macro=true";
import { default as orderBaseDataOqJtMnSeibMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderBaseData.vue?macro=true";
import { default as orderDetailsw3742xeaK1Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderDetails.vue?macro=true";
import { default as orderHistorynlkerfKYxsMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderHistory.vue?macro=true";
import { default as orderOffersTHPu2l5YD7Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderOffers.vue?macro=true";
import { default as _91id_936bVgyfmz9LMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id].vue?macro=true";
import { default as indexCdFTjqnNyaMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/index.vue?macro=true";
import { default as organisationBaseDataH7Wf8bqHzjMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93VLU9TJiKK8Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/[id].vue?macro=true";
import { default as indexXiZqSV9Sx3Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/index.vue?macro=true";
import { default as organisationscvbknc9Ip9Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations.vue?macro=true";
import { default as dungeonEHlHloyCAhMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsfsesf3YqsWMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexYcONhxvKOCMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index.vue?macro=true";
import { default as languagelhQ03sXS8aMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/language.vue?macro=true";
import { default as _91id_93ASbdMzsh1cMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexEU5rzzSiQyMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93Y27aAVFApsMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as index5hdF68VY1NMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settings6c7gWrfFJbMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings.vue?macro=true";
import { default as tenderBaseDataQ9kentmV83Meta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/[id]/tenderBaseData.vue?macro=true";
import { default as _91id_93DvwDLjGXzVMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/[id].vue?macro=true";
import { default as indexG3LI0syvFtMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/index.vue?macro=true";
import { default as userActivitiesIeU6b4DrwTMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatavsdue2DJWWMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsCtB1GMYNcxMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93XGZqIcHsOiMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id].vue?macro=true";
import { default as indexpByQ301TiRMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/index.vue?macro=true";
import { default as usersV5qiBOoX8CMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93f0FIukyWTKMeta } from "/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth-company",
    path: "/auth/company",
    meta: indexswghwu7igrMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/company/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: index3a84ZWKTbdMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexD9nNNF1MRWMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData",
    path: "/baseData",
    meta: baseDataGD4NBWjWHFMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData.vue").then(m => m.default || m),
    children: [
  {
    name: "baseData-categories-id",
    path: "categories/:id()",
    meta: _91id_93XzmVa3bv2PMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "categories",
    meta: indexfaHeR5ZBBiMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "costCenters/:id()",
    meta: _91id_93euujCp3xZ6Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "costCenters",
    meta: indexaMdq93EbBzMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-mailTemplates",
    path: "mailTemplates",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/baseData/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "company",
    path: "/company",
    meta: companyogaHkdCGkEMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: "company-companyBaseData",
    path: "companyBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/company/companyBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_936bVgyfmz9LMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-orderBaseData",
    path: "orderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderDetails",
    path: "orderDetails",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderDetails.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderHistory",
    path: "orderHistory",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderHistory.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderOffers",
    path: "orderOffers",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/[id]/orderOffers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexCdFTjqnNyaMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: organisationscvbknc9Ip9Meta?.name,
    path: "/organisations",
    meta: organisationscvbknc9Ip9Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93VLU9TJiKK8Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexXiZqSV9Sx3Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings6c7gWrfFJbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93ASbdMzsh1cMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexEU5rzzSiQyMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93Y27aAVFApsMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: index5hdF68VY1NMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tenders-id",
    path: "/tenders/:id()",
    meta: _91id_93DvwDLjGXzVMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "tenders-id-tenderBaseData",
    path: "tenderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/[id]/tenderBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tenders",
    path: "/tenders",
    meta: indexG3LI0syvFtMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/tenders/index.vue").then(m => m.default || m)
  },
  {
    name: usersV5qiBOoX8CMeta?.name,
    path: "/users",
    meta: usersV5qiBOoX8CMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93XGZqIcHsOiMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexpByQ301TiRMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93f0FIukyWTKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241213170306/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]