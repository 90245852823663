export default {
    title: 'Ausschreibungen',
    id: 'ID',
    user_id: 'Ausgeschrieben von',
    category_id: 'Kategorie',
    positions: 'Positionen',
    positionDocument: 'Dokument',
    offer: 'Angebot',
    visible_until: 'Sichtbar bis',
    created_at: 'Vom',
    createOffer: 'Angebot anlegen / bearbeiten',
}
