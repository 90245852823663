export const dashboard: Dashboard = {
    rowHeight: 175,
    editable: true,
    columns: {
        'xxs': 1,
        'xs': 2,
        'sm': 3,
        'md': 4,
        'lg': 4,
    },
    widgets: [{
        name: 'demo.openAi',
        resizable: false
    }]
}